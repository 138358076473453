class MessagesWebSocket {
  #ws = undefined
  #buffer = []
  #delay = 1

  get uri () {
    const location = document.location
    const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:'

    return `${protocol}//${location.host}/messages/ws`
  }

  connect (message) {
    if (!WebSocket || this.#ws) { return this }

    const open = function (evt) {
      if (evt.target.readyState === WebSocket.OPEN) {
        this.#buffer.forEach(payload =>
          evt.target.send(JSON.stringify(payload))
        )

        this.#buffer = []
      }
    }

    const close = function (evt) {
      this.#ws = undefined

      if (!evt.wasClean) {
        // Подключение оборвалось; начнём бесконечную серию попыток подключения с паузами
        // между попытками в 2^n секунд, 0 <= n <= 8.
        this.#delay = Math.max(1, this.#delay)
        setTimeout(this.connect.bind(this), this.#delay * 1000)

        this.#delay *= 2
        if (this.#delay > 256) {
          this.#delay = 1
        }
      }
    }

    this.#ws = new WebSocket(this.uri)
    this.#ws.addEventListener('open', open.bind(this))
    this.#ws.addEventListener('close', close.bind(this))

    if (message && message instanceof Function) {
      this.#ws.addEventListener('message', message.bind(this))
    }

    return this
  }

  send (payload) {
    if (!this.#ws || this.#ws.readyState !== WebSocket.OPEN) {
      // Вебсокет-соединение ещё не установлено, отложим отправку.
      this.#buffer.push(payload)
      return this
    }

    this.#ws.send(JSON.stringify(payload))
    return this
  }

  close () {
    if (!this.#ws) { return this }

    this.#ws.close()
    this.#ws = undefined
    return this
  }
}

export const messagesWebSocket = new MessagesWebSocket()
