<template>
  <UBanner
    v-if="!loading"
  >
    <VContainer
      fluid
      class="box"
    >
      <h1 class="page-title">
        {{ localDocumentTitle }}
      </h1>

      <VRow v-if="chats.length">
        <VCol
          v-show="!$vuetify.breakpoint.smAndDown || !currentChatID"
          cols="12"
          lg="3"
          md="4"
        >
          <ChatsList :chats="chats" />
        </VCol>
        <VCol
          v-show="!$vuetify.breakpoint.smAndDown || currentChatID"
          cols="12"
          lg="9"
          md="8"
        >
          <VCard class="fill-height">
            <ChatsListItem
              v-if="currentChatID"
              :key="currentChatID"
              :chat="currentChat"
              :message="currentMessage"
              @deleteChat="onDeleteChat"
              @deleteMessage="onDeleteMessage"
              @unselectMessage="onUnselectMessage"
              @editMessage="onEditMessage"
            >
              <ChatMessages
                v-if="messages.length"
                :messages="messages"
                :current-message-i-d="currentMessageID"
                @read="onReadChat"
                @selectMessage="onSelectMessage"
              />

              <template #footer>
                <ChatQuickForm
                  :chat-i-d="currentChatID"
                  :message="currentMessage"
                  :is-edit="isEdit"
                  :lesson-title="lessonTitle"
                  @sendMessage="onSendMessage"
                />
              </template>
            </ChatsListItem>

            <UPlaceholder
              v-else
              class="absolute-align-center"
              type="messages"
            >
              <p>{{ $t('$app.nothing_selected') }}</p>
              <p class="subtitle-1">
                {{ $t('$app.chat.select_title') }}
              </p>
              <VBtn
                color="primary"
                :to="{ query: { chat: 0 } }"
              >
                {{ $t('write_to_us.one') }}
              </VBtn>
            </UPlaceholder>
          </VCard>
        </VCol>
      </VRow>

      <UPlaceholder
        v-else
        type="messages"
      >
        <p class="subtitle-1">
          {{ $t('messages_empty_message.one') }},
        </p>
        <VBtn
          color="primary"
          :to="{ query: { chat: 0 } }"
        >
          {{ $t('write_to_us.one') }}
        </VBtn>
      </UPlaceholder>

      <VFabTransition>
        <VBtn
          v-if="!currentChatID && $vuetify.breakpoint.smAndDown"
          :title="$t('$app.chat_create.action_title')"
          :to="{ query: { chat: 0 } }"
          fab
          color="primary"
          fixed
          bottom
          right
        >
          <VIcon>create</VIcon>
        </VBtn>
      </VFabTransition>
      <ChatForm
        v-if="currentChatID === 0"
        :lesson-title="lessonTitle"
        :course-i-d="courseID"
        :course-title="courseTitle"
        @sendMessage="onSendMessage"
      />

      <!-- Добавим компонент для превью изображений -->
      <ImagePreview />
    </VContainer>
  </UBanner>
  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import { readChat, deleteChat, deleteMessage, getChats, getMessages } from '@/api/api'
import documentTitle from '@/mixins/documentTitle'
import { prepareEntity } from '@/utils/common'
import { newMessagesCounter } from '@/utils/messagesCounter'
import { messagesWebSocket } from '@/utils/messagesWebSocket'
import ChatsListItem from '@/components/ChatsListItem.vue'
import ChatsList from '@/components/ChatsList.vue'
import ChatForm from '@/components/ChatForm.vue'
import ChatMessages from '@/components/ChatMessages.vue'
import ChatQuickForm from '@/components/ChatQuickForm.vue'
import UPlaceholder from '@components/UPlaceholder.vue'
import UBanner from '@components/UBanner.vue'
import ImagePreview from '@components/ImagePreview.vue'
import * as getters from '@/store/getters/types'

export default {
  name: 'TheMessenger',

  components: {
    ChatsList,
    ChatsListItem,
    ChatForm,
    ChatMessages,
    ChatQuickForm,
    UPlaceholder,
    UBanner,
    ImagePreview
  },
  mixins: [documentTitle],

  props: {
    title: {
      type: String,
      default: ''
    },

    courseID: {
      type: Number,
      default: 0
    },

    courseTitle: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      initialChats: [],
      initialMessages: [],
      currentChatID: null,
      currentMessageID: 0,
      documentTitle: '$sdo.lc_menu_messages.other',
      isEdit: false,
      loading: true,
      lessonTitle: this.title
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT,
      now: getters.NOW
    }),

    chats () {
      return (this.initialChats || [])
        .map(this.prepareChat)
        .sort((a, b) => b.last_message_time - a.last_message_time)
    },

    messages () {
      return (this.initialMessages || []).map(this.prepareMessage)
    },

    currentChat () {
      if (!this.currentChatID) { return }
      return this.chats.find(chat => chat.id === this.currentChatID)
    },

    currentMessage () {
      if (!this.currentMessageID) { return }
      return this.messages.find(message => message.id === this.currentMessageID)
    }
  },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (parseInt(val.query.chat, 10) === parseInt(oldVal.query.chat, 10)) {
          return
        }

        this.currentChatID = parseInt(val.query.chat, 10)
        this.initialMessages = []
        this.lessonTitle = ''
      }
    },

    currentChatID: {
      handler: async function (val) {
        if (val) {
          const { messages } = await getMessages(val)
          this.initialMessages = messages || []
          this.currentMessageID = 0
          this.isEdit = false
        }
      }
    }
  },

  created () {
    this.register()
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    prepareChat (chat) {
      chat = prepareEntity(chat)
      if (chat && chat instanceof Object) {
        chat.is_sender = chat.sender_id === this.account.id
        chat.last_message_time = new Date(chat.last_message_time)
        chat.last_read_message_time = new Date(chat.last_read_message_time)
        chat.new_messages_count = chat.new_messages_count || 0
      }
      return chat
    },

    prepareMessage (message) {
      message = prepareEntity(message)
      if (message && message instanceof Object) {
        message.is_sender = message.sender_id === this.account.id
      }

      return message
    },

    onSendMessage ({ chat, message }) {
      if (!chat?.id || !message?.id) { return }
      this.isEdit = false
    },

    onSelectMessage (messageID) {
      if (this.currentMessageID !== messageID) {
        this.currentMessageID = messageID || 0
        return
      }

      this.onUnselectMessage()
    },

    onUnselectMessage () {
      this.currentMessageID = 0
      this.isEdit = false
    },

    updateChats (chat) {
      // Попробуем найти чат в списке
      for (let i = 0; i < this.initialChats.length; i++) {
        if (chat.id === this.initialChats[i].id) {
          this.$set(this.initialChats, i, chat)
          return
        }
      }

      this.initialChats.push(chat)
    },

    updateMessages (message) {
      // Попробуем найти сообщение в списке
      for (let i = 0; i < this.initialMessages.length; i++) {
        if (message.id === this.initialMessages[i].id) {
          this.$set(this.initialMessages, i, message)
          return
        }
      }

      this.initialMessages.push(message)
    },

    async onReadChat () {
      const chat = this.chats.find(_chat => _chat.id === this.currentChatID)
      if (!chat) { return }
      await readChat(chat.id)
      await newMessagesCounter.update()
      this.$set(chat, 'new_messages_count', 0)
    },

    async onDeleteChat (chat) {
      const formData = new FormData()
      formData.append('_confirm', 1)
      await deleteChat(chat.id, formData)
      chat.is_deleted = true
      this.$router.push({ name: 'messenger' }).catch(() => {})
    },

    async onDeleteMessage (message) {
      const formData = new FormData()
      formData.append('_confirm', 1)
      await deleteMessage(message.chat_id, message.id, formData)
      message.is_deleted = true
      this.currentMessageID = 0
    },

    onEditMessage () {
      if (this.currentMessage) {
        this.isEdit = true
      }
    },

    watchNewMessage (evt) {
      const { chat, message } = JSON.parse(evt.data)
      if (message?.id && chat?.id) {
        chat.last_message_id = message.id
        chat.last_message_time = message.created_at

        if (message.chat_id === this.currentChatID) {
          // Сообщение пришло в открытый пользователем чат, добавим его прямиком в список.
          this.updateMessages(message)

          // Сразу прочитаем сообщение текущего чата.
          if (message.sender_id !== this.account.id) {
            this.onReadChat()
          }

          this.updateChats(chat)
          return
        }

        if (message.sender_id !== this.account.id) {
          chat.new_messages_count = (chat.new_messages_count || 0) + 1
        }

        this.updateChats(chat)
      }
    },

    unregister () {
      messagesWebSocket.close()
    },

    async register () {
      messagesWebSocket.connect(this.watchNewMessage)
      const { chats } = await getChats()
      this.initialChats = chats || []

      this.currentChatID = parseInt(this.$route.query.chat, 10)
      this.loading = false
    }
  }
}
</script>
