<template>
  <VForm
    class="messenger-quick-form"
    @submit.prevent="send"
  >
    <FileList v-model="files" />
    <VTextarea
      v-model.trim="body"
      required
      :placeholder="$t('reply.one')"
      solo
      flat
      single-line
      rows="1"
      auto-grow
      hide-details
      background-color="#eee"
      @keydown.enter.prevent.exact="send"
      @keydown.shift.enter.prevent.exact="addLineBreak"
    >
      <VBtn
        slot="prepend"
        :title="$t('attach_files.one')"
        icon
        large
        tag="label"
      >
        <VIcon>attach_file</VIcon>
        <input
          v-file="files"
          type="file"
          multiple
          hidden
          :accept="accept"
        >
      </VBtn>

      <VBtn
        slot="append-outer"
        type="submit"
        :title="$t('send.one')"
        icon
        large
        color="primary"
        :disabled="!body"
        :loading="isSubmitting"
      >
        <VIcon>send</VIcon>
      </VBtn>
    </VTextarea>
  </VForm>
</template>

<script>
import { sendMessage } from '@/api/api'
import { accept } from '@/utils/files'
import FileList from '@/components/FileList.vue'

export default {
  name: 'ChatQuickForm',
  components: { FileList },

  props: {
    chatID: {
      type: Number,
      required: true
    },

    message: {
      type: Object,
      default: () => ({})
    },

    isEdit: {
      type: Boolean,
      default: false
    },

    lessonTitle: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      files: [],
      accept,
      isSubmitting: false,
      body: this.lessonTitle ? `${this.$t('$app.default_message_text')} "${this.lessonTitle}": ` : ''
    }
  },

  watch: {
    isEdit (val) {
      if (val) {
        this.body = this.message.body
        this.files = Array.from(this.message.files || [])
        return
      }

      this.reset()
    }
  },

  methods: {
    addLineBreak () {
      this.body += '\n'
    },

    async send () {
      if (!this.body.length) { return }
      this.isSubmitting = true

      const formData = new FormData()
      formData.append('body', this.body)
      this.files.forEach(f => {
        if (f instanceof File) {
          formData.append('files', f, f.name)
        }
      })

      try {
        const response = await sendMessage(this.chatID, this.message?.id || 0, formData)
        this.$emit('sendMessage', response)
        this.reset()
      } finally {
        this.isSubmitting = false
      }
    },

    reset () {
      this.body = ''
      this.files = []
    }
  }
}
</script>

<style lang="scss">
  .messenger-quick-form textarea {
    max-height: 140px;
    overflow: auto;
  }
</style>
