<template>
  <div
    ref="container"
    v-scroll.self="checkButtonIsShow"
    class="messages"
  >
    <div
      v-for="messageGroup in grouppedMessages"
      :key="messageGroup.key"
      class="messages__group"
    >
      <p :class="['messages__sender', {'text-lg-left text-right': !messageGroup.isSender}]">
        {{ messageGroup.sender }}
      </p>

      <ChatMessage
        v-for="message in messageGroup.messages"
        :key="message.id"
        :message="message"
        :current-message-id="currentMessageID"
        @selectMessage="$emit('selectMessage', $event)"
      />
    </div>

    <VFabTransition>
      <VBtn
        v-show="isShowButton"
        title="Вниз"
        color="primary"
        class="messages__scroll-button"
        fab
        small
        @click="toScroll()"
      >
        <VIcon>keyboard_arrow_down</VIcon>
      </VBtn>
    </VFabTransition>
  </div>
</template>

<script>
import ChatMessage from '@/components/ChatMessage.vue'

export default {
  name: 'ChatMessages',
  components: {
    ChatMessage
  },

  props: {
    messages: {
      type: Array,
      required: true
    },

    currentMessageID: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      isShowButton: false
    }
  },

  computed: {
    grouppedMessages () {
      const filteredMessages = this.messages.filter(message => !message.is_deleted)

      const groupBySenderTitle = (acc, currentMessage, idx, array) => {
        const previousMessage = idx === 0 ? null : array[idx - 1]

        if (previousMessage && currentMessage.sender_title === previousMessage.sender_title) {
          const lastIndexAcc = acc.length - 1
          acc[lastIndexAcc].messages.push(currentMessage)
        } else {
          acc.push({
            sender: currentMessage.sender_title,
            isSender: currentMessage.is_sender,
            id: `message-group-${idx}`,
            messages: [currentMessage]
          })
        }

        return acc
      }

      return filteredMessages.reduce(groupBySenderTitle, [])
    },

    countMessages () {
      return this.messages.length
    }
  },

  watch: {
    countMessages (val) {
      // Последнее сообщение исходящее прокрутим список сообщений к нему
      if (this.messages[val - 1]?.is_sender) {
        setTimeout(() => this.toScroll())
      }

      this.checkButtonIsShow()
    }
  },

  mounted () {
    this.toScroll()
    this.$emit('read')
  },

  methods: {
    checkButtonIsShow () {
      const container = this.$refs.container
      if (!container) { return }

      const offset = (container.offsetHeight || 0) + 32
      const maxScrollTop = (container.scrollHeight || 0)
      const minPositionTop = (container.scrollTop || 0) + offset
      if (minPositionTop < maxScrollTop) {
        this.isShowButton = true
        return
      }

      this.isShowButton = false
    },

    toScroll () {
      const container = this.$refs.container
      this.$vuetify.goTo(container.scrollHeight || 0, {
        container,
        duration: 0
      })
    }
  }
}
</script>

<style lang="scss">
  .messages {
    overflow: auto;
    height: 100%;
  }

  .messages__scroll-button {
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
    margin: 1rem;
  }

  .messages__sender.messages__sender {
    font-size: 0.875rem;
    margin: 0;
  }

  .messages__group {
    padding: 0 1rem;
    margin: 1rem 0;
  }
</style>
